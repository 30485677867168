import React from 'react';
import tw from "twin.macro";

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const MapColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;

const ContactMaps = () => {
  return (
    <MapColumn>
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3502.7870371621157!2d77.06124907549983!3d28.606164975679103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM2JzIyLjIiTiA3N8KwMDMnNDkuOCJF!5e0!3m2!1sen!2sin!4v1706461852305!5m2!1sen!2sin"
          title="Google Maps"
          // srcDoc='Google Maps'
          width="600"
          height="400"
          frameBorder="0"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
    </MapColumn>
     
  );
};

export default ContactMaps;
