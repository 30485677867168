import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImageSM";
import ProfessionalService from "components/features/DashedBorderSixFeatures";
import TrackRecords from "components/features/TwoColSingleFeatureWithStatsSM.js";
import OurExpertise from "components/features/TwoColWithTwoFeaturesAndButtonsSM.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import Testimonial from "components/testimonials/TwoColumnWithImageSM.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooterSM.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import AdvisorImage from "images/profiles/advisor_nilesh_tiwari.jpeg"

export default () => (
  <AnimationRevealPage>
    <Hero />
    <TrackRecords />
    <ProfessionalService />
    <OurExpertise />
    {/* <Portfolio /> */}
    <Testimonial
      // subheading="<empty>"
      // heading={
      //   <>
      //     Our <span tw="text-primary-500">Advisors</span>
      //   </>
      // }
      // description="Here are what some of our amazing advisors are saying about our marketing professionals."
      // description="<empty>"
      testimonials={[
        {
          // imageSrc:
          //   "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
           imageSrc:
           AdvisorImage,
          quote:
            "Dr. Neelesh Tiwari is the founder of the Hospital. His aim is to create an institution that provided quality healthcare services. His inspiration to start a brain centre stemmed from the untimely and sad death of a patient, who could not be saved due to his unawareness of whether to see a neurologist/neurosurgeon/psychiatrist which led to marked delay in treatment and ultimately death.",
          customerName: "Dr. Neelesh Tiwari",
          customerTitle: "MS, WBC hospital"
        }
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    {/* <Blog /> */}
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
