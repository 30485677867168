import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/lightSM.js";
import Footer from "components/footers/MiniCenteredFooterSM.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";


// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";
import AboutUsImage1 from "images/doc-with-vr.jpg";
import VisionDoMoreImage from "images/vision-do-more.jpg";



const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        // subheading={<Subheading>About SattvikMinds</Subheading>}
        heading="About SattvikMinds"
        description="At #SattvikMinds, We combine virtual reality therapy with our Evidence-based digital health solutions to improve recovery outcomes and clinician efficiency. We saves time and efforts for clients. Enables adherence and retention for clinicians. VR Therapy is implemented consistently and outcomes, scalable and rapid."
        subheading="<empty>"
        buttonRounded={false}
        primaryButtonText="<empty>"
        imageSrc={AboutUsImage1}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        // subheading={<Subheading>Our Vision</Subheading>}
        heading="Our Vision"
        subheading="<empty>"
        description="We want to impact in the billions of peoples worldwide by offering accessible and affordable mental healthcare solutions through the use of modern technology VIRTUAL Reality and our experienced team . We want to create a future where mental health care is as accessible and important, and where everyone has access to the support they need to live happy and healthy lives, all over india from metro cities to small villages of india. "
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc={VisionDoMoreImage}
        // imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
