import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as UsersIcon } from "feather-icons/dist/icons/users.svg";
// import { ReactComponent as ClipboardIcon } from "feather-icons/dist/icons/clipboard.svg";
import { ReactComponent as SunIcon } from "feather-icons/dist/icons/sun.svg";
import { ReactComponent as MessageSquareIcon } from "feather-icons/dist/icons/message-square.svg";
import { ReactComponent as LockIcon } from "feather-icons/dist/icons/lock.svg";
import { ReactComponent as TrendingUpIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as CodepenIcon } from "feather-icons/dist/icons/codepen.svg";



// import TeamIllustrationSrc from "images/team-illustration-2.svg";
import TeamIllustrationSrc from "images/girl_vr_extended.jpeg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-500`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      We have the most <span tw="text-primary-500">professional</span> Psychologist.
    </>
  ),
  description = "Our expertise lies in harnessing the power of virtual reality to create immersive therapeutic experiences, revolutionizing the landscape of mental health treatments. we offer therapeutic interventions that cater to a spectrum of mental health challenges, providing individuals with a unique and effective avenue for self-discovery and healing.",
  // primaryButtonText = "See Our Portfolio",
  // primaryButtonUrl = "https://timerse.com",
  features = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: UsersIcon,
      title: "Licensed Mental Health Professionals",
      description: "Access to a diverse team of licensed psychologists, psychiatrists, therapists, and counselors."
    },
    {
      Icon: SunIcon,
      title: "Mindfulness and Relaxation Resources",
      description: "Guided meditations, relaxation exercises, and mindfulness content."
    },
    {
      Icon: CodepenIcon,
      title: "Cutting-edge technology",
      description: "Latest technological solutions in harnessing the power of virtual reality and AI to create immersive therapeutic experiences, revolutionizing the landscape of mental health treatments"
    },
    // {
    //   Icon: BriefcaseIcon,
    //   title: "Professionalism",
    //   description: "Our commitment to excellence is embodied by our experienced team, where doctors and psychologists collaborate harmoniously with technologists to push the boundaries of what is possible in mental health care. Through this fusion of advanced technology and human insight, we aspire to redefine mental health interventions, offering a pioneering approach that reflects the synergy between cutting-edge solutions and compassionate expertise."
    // },
    // {
    //   Icon: MoneyIcon,
    //   title: "Personalized Assessments",
    //   description: "Interactive assessments to understand users' mental health needs and preferences."
    // },
    // {
    //   Icon: MessageSquareIcon,
    //   title: "Secure Messaging and Journaling",
    //   description: "Private messaging with mental health professionals and a secure journaling feature."
    // },
    // {
    //   Icon: ClipBoardIcon,
    //   title: "Psychoeducation Materials",
    //   description: "Informative articles, videos, and resources on various mental health topics."
    // },
    {
      Icon: TrendingUpIcon,
      title: "Progress Tracking",
      description: "Tools for users and professionals to monitor and track mental health progress."
    },
    {
      Icon: LockIcon,
      title: "Data Privacy and Security",
      description: "Stringent measures to protect user data and ensure confidentiality"
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
            {/* <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
